define("discourse/plugins/discourse-anonymous-moderators/discourse/initializers/discourse-anonymous-moderators", ["exports", "@ember/service", "discourse/lib/icon-library", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/plugins/discourse-anonymous-moderators/discourse/components/anonymous-moderator-tab"], function (_exports, _service, _iconLibrary, _pluginApi, _url, _anonymousModeratorTab) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initializeAnonymousUser(api) {
    api.registerUserMenuTab(UserMenuTab => {
      return class extends UserMenuTab {
        static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
        #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
        id = "anonymous_moderator";
        icon = "user-secret";
        panelComponent = (() => _anonymousModeratorTab.default)();
        get shouldDisplay() {
          return this.currentUser?.can_become_anonymous_moderator || this.currentUser?.is_anonymous_moderator;
        }
      };
    });
    api.decorateWidget(`poster-name:after`, dec => {
      const username = dec.attrs.userCustomFields?.parent_user_username;
      if (!username) {
        return null;
      }
      return dec.h("span.poster-parent-username", dec.h("a.anon-identity", {
        attributes: {
          "data-user-card": username,
          href: (0, _url.userPath)()
        }
      }, [(0, _iconLibrary.iconNode)("user-secret"), ` ${username}`]));
    });
  }
  var _default = _exports.default = {
    name: "discourse-anonymous-moderators",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      if (siteSettings.anonymous_moderators_enabled) {
        (0, _pluginApi.withPluginApi)("0.8", initializeAnonymousUser);
      }
    }
  };
});